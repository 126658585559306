


import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()

export class ManageCaregiversService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getNotificationList(data: any) {
        return this.http.post(this.url + 'notification/getNotifications', data);
    }

    createNotification(data: any) {
        return this.http.post(this.url + 'notification/saveNotification', data);
    }

    deleteNotification(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'notification/deleteNotification', httpOptions);
    }

    updateNotification(data: any) {
        return this.http.post(this.url + "notification/updateNotification", data);
    }


    getCaregiverList(data: any) {
        return this.http.post(this.url + "user/list-caregiver", data);
    }

    deleteCaregivers(data: any) {
        return this.http.post(this.url + "user/delete-users", data);
    }

    approveCaregiver(data: any) {
        return this.http.post(this.url + "user/approve-caregiver", data);
    }

    getCaregiverById(data: any) {
        return this.http.post(this.url + "user/view-caregiver", data);
    }

    getAvailabiltyList(data: any) {
        return this.http.post(this.url + "user/caregiver-availability", data);
    }

    setStatus(data: any) {
        return this.http.post(this.url + "user/change-status", data);
    }

    createReview(data: any) {
        return this.http.post(this.url + "user/admin/add-review", data);
    }

    uploadImage(data: any) {
        return this.http.post(this.url + "user/upload-image", data);
    }

    getAppoinmentList(data:any) {
        return this.http.post(this.url + 'appointment/list-appointments', data);
      }

      getReviewList(data:any) {
        return this.http.post(this.url + 'user/admin/list-review', data);
      }

      reviewTutorial(id: any) {
        return this.http.delete(this.url + "user/admin/review/" + id);
      }

      hideTutorial(id: string, payload: { status: boolean }) {
        const url = `${this.url}user/admin/hide-review/${id}`;
        return this.http.post(url, payload);
    }
    
    
      

}